import React, { memo, useState } from 'react'

import { getCurrentLng } from '@nickel/i18n'
import L10nProvider from '@nickel/l10n/L10nProvider'
import { SupportedCountryISO2 } from '@nickel/l10n/types'

import { getCountryFromLanguage } from '../../i18n/utils'
import { FormType } from '../../types/forms'
import Template from '../Template'

import Editable from './Editable'
import Printable from './Printable'

const Fatca = () => {
    const currentLanguage = getCurrentLng()
    const currentCountry = getCountryFromLanguage(currentLanguage)
    const code = SupportedCountryISO2[currentCountry as SupportedCountryISO2]

    const [country] = useState<SupportedCountryISO2>(code)

    return (
        <L10nProvider value={country}>
            <Template formType={FormType.FATCA} EditableContent={Editable} PrintableContent={Printable} />
        </L10nProvider>
    )
}

export default memo(Fatca)
